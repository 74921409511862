<template>
  <div class="navbar">
    <!-- TODO: check unsupported prop: clipped-left, app -->
    <v-app-bar v-if="$auth.isAuthenticated && this.display.mdAndDown" color="white">
      <template v-if="this.display.mdAndDown">
        <!-- TODO: check unsupported prop: contain -->
        <v-img
          alt="Firmen Logo"
          class="shrink ml-4"
          src="/img/logo.png"
          width="140"
          min-height="16"
          style="flex: unset"
          @click="goHome"
        />

        <v-spacer />
        <div class="d-flex align-center justify-center">
          <v-btn
            v-if="isEntryPlan"
            size="small"
            :to="{ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } }"
            :style="{
              'background-color': isPlatformMarkero ? '#FFF5EA' : $vuetify.theme.themes.light.primary,
              color: isPlatformMarkero ? $vuetify.theme.themes.light.primary : '#FFF'
            }"
            class="upgrade-button"
            @click="$tracking.event('AppBar', 'Clicked', 'Upgrade', 'Toad')"
          >
            <v-icon class="mr-2"> mdi-one-up </v-icon>
            {{ $t('navigation.usage-counter.upgrade') }}
          </v-btn>
          <v-list-item
            v-if="news"
            :id="news.trackingLabel?.toLowerCase()"
            :key="news.to"
            :to="news.to"
            :href="news.href"
            :target="news.target"
            :rel="news.rel"
            @click="handleItemClick(news.trackingLabel)"
          >
            <v-list-item icon class="news-icon">
              <span v-if="unreadNewsCount > 0" class="unread-messages-count">
                {{ unreadNewsCount }}
              </span>
              <v-icon>
                {{ news.icon }}
              </v-icon>
            </v-list-item>
          </v-list-item>
        </div>

        <v-app-bar-nav-icon v-if="$auth.isAuthenticated" color="primary" @click.stop="showDrawer = !showDrawer" />
      </template>
    </v-app-bar>
    <!-- TODO: check unsupported prop: clipped, app -->
    <v-navigation-drawer
      v-if="$auth.isAuthenticated"
      ref="drawer"
      v-model="showDrawer"
      class="px-2 pb-3 navigation-drawer-height"
      rail-width="60"
      :rail="this.display.lgAndUp && isDrawerCollapsible"
      :width="270"
      :location="this.display.mdAndDown ? 'right' : undefined"
      :expand-on-hover="isDrawerCollapsible"
      @update:rail="updateDrawerState"
    >
      <!-- Company Logo -->
      <template v-slot:prepend>
        <v-list-item
          id="company-logo"
          to="/dashboard"
          exact
          lines="two"
          link
          class="px-2 rounded-lg"
          @click="trackClick('Logo')"
        >
          <template #prepend>
            <v-avatar tile>
              <v-img
                :alt="$t('navigation.logo.alt-texts.0')"
                src="/img/logo-icon.png"
                width="40"
                height="40"
                @click="goHome"
              />
            </v-avatar>
          </template>

          <v-list-item-title v-if="isDrawerOpen">
            <v-img :alt="$t('navigation.logo.alt-texts.1')" src="/img/logo-name.png" @click="goHome" />
          </v-list-item-title>
        </v-list-item>
      </template>

      <!-- User Section -->
      <!-- User Menu Section -->
      <FeatureFlag :feature-name="featureNames.USAGE_COUNTER" @feature-change="onUsageCounterFeatureChange">
        <v-divider />
        <v-list-item class="px-0">
          <v-menu v-model="userMenuOpen" :close-on-content-click="true">
            <template v-slot:activator="{ props }">
              <div class="d-flex align-center cursor-pointer user-section" v-bind="props">
                <v-list-item class="py-0 px-0">
                  <div class="d-flex align-center w-100 ml-2">
                    <v-img v-if="$auth.user.picture"  alt="User Avatar" class="portrait" :src="$auth.user.picture" />
                    <div class="d-flex flex-column flex-grow-1 user-details">
                      <div class="pl-0">
                        <v-list-item-title
                          v-if="$auth.user.given_name && $auth.user.family_name"
                          class="truncate mb-0"
                          :title="$auth.user.given_name + ' ' + $auth.user.family_name"
                        >
                          {{ $auth.user.given_name + ' ' + $auth.user.family_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="company"
                          class="text-caption grey--text truncate pl-0"
                          :title="company?.name"
                        >
                          {{ company?.name }}
                        </v-list-item-subtitle>
                      </div>
                      <v-spacer />
                    </div>

                    <v-btn v-if="isDrawerOpen" icon size="medium" class="menu-button" :ripple="false">
                      <v-icon class="text-grey-lighten-1">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </div>
                </v-list-item>
              </div>
            </template>

            <v-list style="z-index: 10000">
              <v-list-item to="/settings/profile" density="compact" exact link @click="trackClick('Profile')">
                <template #prepend>
                  <v-icon class="mr-2">mdi-account</v-icon>
                </template>
                <v-list-item-title>{{ $t('navigation.labels.profile') }}</v-list-item-title>
              </v-list-item>
              <v-list-item density="compact" @click="logout">
                <template #prepend>
                  <v-icon class="mr-2">mdi-logout</v-icon>
                </template>
                <v-list-item-title>{{ $t('navigation.labels.logout') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
        <v-divider />
      </FeatureFlag>
      <div class="navigation-container">
        <!-- Primary Navigation -->
        <v-list class="mt-0 primary-navigation">
          <v-list-item
            v-for="item in navItems"
            :key="item.to"
            :to="item.to"
            class="rounded-lg nav-item"
            active-class="rounded-lg grey lighten-3 font-weight-bold"
            :active="isRouteActive(item.to)"
            color="primary"
            @click="handleItemClick(item.trackingLabel)"
          >
            <template #prepend>
              <v-badge
                v-if="item.badge && item.badge.condition({ showRegeneration })"
                bordered
                location="bottom"
                :color="item.badge.color"
                dot
                offset-x="10"
                offset-y="10"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-badge>
              <v-badge v-else-if="item.isNew && showIsNew" content="NEW" color="orange" offset-x="10" offset-y="10">
                <v-icon>{{ item.icon }}</v-icon>
              </v-badge>
              <v-icon v-else>
                {{ item.icon }}
              </v-icon>
            </template>

            <v-list-item>
              <v-list-item-title>
                {{ $t(item.label) }}
                <v-badge v-if="item.isBeta" content="BETA" color="orange" class="ml-2" />
              </v-list-item-title>
            </v-list-item>
          </v-list-item>
        </v-list>

        <v-divider />

        <!-- Secondary Navigation -->
        <v-list density="compact" class="bottom-navigation">
          <v-list-item
            v-for="item in filteredNavItemsBottimmo"
            class="nav-item"
            :id="item.trackingLabel.toLowerCase()"
            :key="item.to"
            :to="item.to || null"
            :href="item.href || null"
            :target="item.target"
            :rel="item.rel"
            @click="handleItemClick(item.trackingLabel)"
          >
            <template #prepend>
              <span v-if="item.trackingLabel === 'News' && unreadNewsCount > 0" class="unread-messages-count">
                {{ unreadNewsCount }}
              </span>
              <v-icon>{{ item.icon }}</v-icon>
            </template>
            <template #title>
              <v-badge
                v-if="item.badge && item.badge.condition({ showRegeneration })"
                :color="item.badge.color"
                :icon="item.badge.icon"
              >
                <v-list-item>
                  <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
                </v-list-item>
              </v-badge>
              <v-list-item v-else>
                <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
              </v-list-item>
            </template>
            <template #append>
              <v-list-item-action v-if="item.href">
                <v-icon color="grey lighten-1" size="14"> mdi-open-in-new </v-icon>
              </v-list-item-action>
          </template>
          </v-list-item>
        </v-list>
      </div>

      <!-- User Area -->
      <template v-slot:append>
        <FeatureFlag :feature-name="featureNames.USAGE_COUNTER">
          <template v-slot="props">
            <v-list-item v-if="isDrawerOpen && company" class="usage-counter" @mounted="isUsageCounterActive = true">
              <UsageCounter :product-type="productType" v-bind="props" />
            </v-list-item>
          </template>
          <template #fallback>
            <!-- User Area -->
            <v-expand-x-transition v-if="isEntryPlan && isDrawerOpen">
              <v-btn
                block
                color="primary"
                class="my-4"
                :to="{ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } }"
                @click="$tracking.event('Sidebar', 'Clicked', 'Upgrade')"
              >
                <v-icon class="mr-2"> mdi-one-up </v-icon>

                <div>
                  {{ $t('navigation.labels.upgrade') }}
                </div>
              </v-btn>
            </v-expand-x-transition>

            <!-- TODO: check unsupported prop: offset-y -->
            <v-menu v-model="isUserMenuOpen" location="top" @mounted="isUsageCounterActive = false">
              <template v-slot:activator="{ props }">
                <v-list-item
                  density="compact"
                  v-bind="props"
                  class="rounded-lg user-item"
                  :class="{ 'bg-grey-lighten-3 pl-3': isDrawerOpen, 'bg-white': !isDrawerOpen }"
                  style="max-height: 60px;"
                  @click="trackClick('User Menu')"
                >
                  <template #prepend>
                    <v-avatar :style="{ marginLeft: isDrawerOpen ? '0' : '-10px' }" style="--v-avatar-height: 36px">
                      <PackageLogo
                        :current-package="productType"
                        :show-label="false"
                        :class="{ 'mr-6': !isDrawerOpen }"
                        :style="{
                          marginTop: isDrawerOpen ? '-34px' : '-38px',
                          marginLeft: isDrawerOpen ? '0px' : '25px'
                        }"
                        size="medium"
                      />
                    </v-avatar>
                  </template>
                  <div class="d-flex justify-space-between ml-2" v-if="isDrawerOpen">
                    <div>
                      <span
                        v-if="$auth.user.given_name && $auth.user.family_name"
                        class="text-caption font-weight-bold"
                      >
                        {{ $auth.user.given_name + ' ' + $auth.user.family_name }}</span
                      >
                      <span
                        v-else
                        class="text-caption font-weight-bold d-inline-block text-truncate"
                        style="max-width: 140px"
                      >
                        {{ $auth.user.email }}</span
                      >
                      <br /><span
                        v-if="company"
                        class="text-caption font-weight-bold grey--text d-inline-block text-truncate"
                        style="max-width: 140px"
                      >
                        {{ company.name }}</span
                      >
                    </div>
                    <v-icon size="small" class="pl-2 mt-4">
                      {{ isUserMenuOpen ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                    </v-icon>
                  </div>
                </v-list-item>
              </template>
              <v-list density="compact" nav>
                <v-list-item to="/settings/profile" density="compact" exact link @click="trackClick('Profile')">
                  <template #prepend>
                    <v-icon>mdi-account</v-icon>
                  </template>
                  <template #title>
                    <v-list-item-title>{{ $t('navigation.labels.profile') }}</v-list-item-title>
                  </template>
                </v-list-item>
                <v-list-item @click="logout">
                  <template #prepend>
                    <v-icon>mdi-logout</v-icon>
                  </template>
                  <template #title>
                    <v-list-item-title>{{ $t('navigation.labels.logout') }}</v-list-item-title>
                  </template>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </FeatureFlag>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'
import COMPANY_BASE from '../../queries/CompanyBase.gql'
import PENDING_CHANGES_FOR_REGENERATION from '../../queries/PendingChangesForRegeneration.gql'
import featureNames from '@/lib/featureNames'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import PackageLogo from '@/components/PackageLogo.vue'
import { productFruits } from 'product-fruits'
import UsageCounter from '@/components/UsageCounter'
import { ProductType as ProductTypes } from '@/lib/productTypes'
import FeatureFlag from '@/components/FeatureFlag.vue'

const BASIC_NAVIGATION = [
  {
    label: 'navigation.labels.dashboard',
    trackingLabel: 'Dashboard',
    to: '/dashboard',
    icon: 'mdi-view-dashboard-outline'
  },
  {
    label: 'navigation.labels.topics',
    trackingLabel: 'Topics',
    to: '/topics',
    icon: 'mdi-database',
    feature: featureNames.TOPIC,
    checkConfigIsActive: (config) => config?.isUserManageable ?? false
  },
  {
    label: 'navigation.labels.leads',
    trackingLabel: 'Leads',
    to: '/leads',
    icon: 'mdi-account-multiple-outline',
    feature: featureNames.LEAD,
    permission: 'lead:read'
  },
  {
    label: 'navigation.labels.landingpages',
    trackingLabel: 'Landingpages',
    to: '/landingpages',
    icon: 'mdi-button-pointer',
    feature: featureNames.LANDINGPAGE,
    permission: 'material:read'
  },
  {
    label: 'navigation.labels.content-creator',
    trackingLabel: 'Content Creator',
    to: '/content-creator',
    icon: 'mdi-brain',
    feature: featureNames.CONTENT_CREATOR,
    permission: 'ai:read'
  },
  {
    label: 'navigation.labels.valuation',
    trackingLabel: 'Valuation',
    to: '/valuation',
    icon: 'mdi-file-chart-check-outline',
    feature: featureNames.VALUATION,
    checkConfigIsActive: (config) => config.isInAppValuationEnabled,
    permission: 'valuation:read'
  },
  {
    label: 'navigation.labels.partner',
    trackingLabel: 'Partner',
    to: '/partner',
    icon: 'mdi-handshake-outline',
    feature: featureNames.PARTNER,
    permission: 'partner:read'
  },
  {
    label: 'navigation.labels.performance',
    trackingLabel: 'Performance Report',
    to: '/performance',
    icon: 'mdi-gauge',
    feature: featureNames.PERFORMANCE_MARKETING,
    permission: 'performance:read'
  },
  {
    label: 'navigation.labels.statistic-dashboard',
    trackingLabel: 'Statistic Dashboard',
    to: '/statistic-dashboard',
    icon: 'mdi-poll',
    feature: featureNames.STATISTIC_DASHBOARD,
    permission: 'lead:read'
  },
  {
    label: 'navigation.labels.settings',
    trackingLabel: 'Settings',
    to: '/settings',
    icon: 'mdi-cog-outline',
    permission: 'settings:read'
  }
]

const ONBOARING_NAVIGATION = [
  {
    label: 'navigation.labels.onboarding',
    trackingLabel: 'Onboarding',
    to: '/onboarding',
    icon: 'mdi-view-dashboard-outline'
  }
]

export default {
  components: {
    PackageLogo,
    UsageCounter,
    FeatureFlag
  },
  mixins: [brandingMixin, featureMixin],
  inject: ['role'],
  props: {
    isDrawerCollapsible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // Will be initially set to boolean by the drawer component
      // depending on the viewport size
      showDrawer: null,
      isDrawerOpen: !(useDisplay().lgAndUp && this.isDrawerCollapsible),
      pendingChangesForRegeneration: {},
      featureNames,
      isUserMenuOpen: false,
      unreadNewsCount: 0,
      display: useDisplay(),
      isRailActive: false,
      ProductTypes,
      isUsageCounterActive: false,
      userMenuOpen: false
    }
  },
  computed: {
    isMobile () {
      return this.display.smAndDown
    },
    languageCode () {
      return this.$i18n.locale
    },
    productType () {
      return this.company?.productType
    },
    isEntryPlan () {
      const entryPlans = [ProductTypes.BEGINNER, ProductTypes.LIGHT, ProductTypes.PIONEER]
      return this.productType && entryPlans.includes(this.productType)
    },
    showIsNew () {
      return !this.isPlatformMarkero
    },
    showRegeneration () {
      return (
        this.isFeatureActive(this.featureNames.REGENERATION) &&
        Object.keys(this.pendingChangesForRegeneration).length > 0
      )
    },
    inOnboarding () {
      return this.company?.state.stringValues.includes('ONBOARDING')
    },
    isInactive () {
      return (
        this.company?.state.stringValues.includes('CREATED') || this.company?.state.stringValues.includes('INACTIVE')
      )
    },
    navItems () {
      if (this.inOnboarding) {
        return this.getActiveNavItems(ONBOARING_NAVIGATION)
      }

      return this.getActiveNavItems(BASIC_NAVIGATION)
    },
    navItemsBottimmo () {
      if (!this.company) {
        return []
      }
      return this.inOnboarding || this.isInactive
        ? this.getActiveNavItems(this.getNavItemsBottimmo().filter(({ onboarding }) => onboarding))
        : this.getActiveNavItems(this.getNavItemsBottimmo())
    },
    filteredNavItemsBottimmo () {
      return this.navItemsBottimmo.filter(({ trackingLabel }) => {
        if (trackingLabel === 'News') {
          return !this.$vuetify.display.mdAndDown
        }
        return true
      })
    },
    news () {
      return this.navItemsBottimmo.find(({ trackingLabel }) => trackingLabel === 'News')
    }
  },
  watch: {
    showDrawer (val) {
      if (val) {
        productFruits.safeExec(() => {
          this.setupNewsList()
        })
      }
    },
    isDrawerCollapsible (val) {
      this.updateDrawerState(val)
    }
  },
  mounted () {
    if (!this.isMobile) {
      productFruits.safeExec(() => {
        this.setupNewsList()
      })
    }
  },
  apollo: {
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated
      }
    },
    pendingChangesForRegeneration: {
      query: PENDING_CHANGES_FOR_REGENERATION,
      variables () {
        return { companyId: this.company.id }
      },
      skip () {
        return !this.company || !this.role.isAuthorizedFor('settings-company:read')
      }
    }
  },
  methods: {
    setupNewsList () {
      const el = document.getElementById('news')
      if (!el) {
        return
      }
      try {
        // eslint-disable-next-line
        window.productFruits.api.announcementsV2.attachNewsWidgetToElement(el)

        // eslint-disable-next-line
        window.productFruits.api.announcementsV2.listen('newsfeed-unread-count-changed', (data) => {
          this.unreadNewsCount = data.count
        })
      } catch (e) {
        // eslint-disable-next-line
        console.error('Could not load news list', e)
      }
    },
    // The name should be changed, the nav is used for both domains
    getNavItemsBottimmo () {
      const navItems = []

      if (this.isPlatformMarkero) {
        const news = this.createNewsNavItem()
        navItems.push(news)
      }

      const support = this.createSupportNavItem()
      navItems.push(support)

      const legalNavItem = {
        label: 'navigation.labels.legal',
        trackingLabel: 'Legal',
        to: '/legal',
        onboarding: true,
        icon: 'mdi-file-document-outline',
        permission: 'legal:read'
      }
      navItems.push(legalNavItem)

      return navItems
    },
    isActive (item) {
      return item.to && this.$route.path === item.to
    },
    createNewsNavItem () {
      return {
        label: 'navigation.labels.news',
        trackingLabel: 'News',
        icon: 'mdi-bell-outline',
        onboarding: false
      }
    },
    createSupportNavItem () {
      const support = {
        label: 'navigation.labels.support',
        trackingLabel: 'HelpCenter',
        icon: 'mdi-lifebuoy',
        onboarding: false,
        permission: 'support:read'
      }

      if (this.isPlatformMarkero && import.meta.env.VITE_APP_PRODUCT_FRUITS_HELP_CENTER) {
        support.href = new URL(this.languageCode, import.meta.env.VITE_APP_PRODUCT_FRUITS_HELP_CENTER).toString()
        support.target = '_blank'
      } else {
        support.to = '/support'
      }

      return support
    },
    getActiveNavItems (items) {
      return items.filter(({ feature: featureSlug, checkConfigIsActive, permission }) => {
        const feature = featureSlug ? this.$features.feature(featureSlug) : null
        if (feature && (!feature.isActive || (checkConfigIsActive && !checkConfigIsActive(feature.config)))) {
          return false
        }

        if (permission && !this.role.isAuthorizedFor(permission)) {
          return false
        }

        return true
      })
    },
    logout () {
      this.trackClick('Logout')
      this.$auth.logout()
    },
    goHome () {
      this.$router
        .push({
          name: this.$auth.isAuthenticated ? 'dashboard' : 'home'
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })
    },
    handleItemClick (href, label) {
      if (this.isMobile) {
        this.showDrawer = false
      }
      this.$router.push(href)
      this.trackClick(label)
    },
    trackClick (label) {
      this.$tracking.event('Sidebar', 'Clicked', label)
    },
    onUsageCounterFeatureChange (event) {
      this.isUsageCounterActive = event.enabled
    },
    updateDrawerState (railState) {
      this.isDrawerOpen = !railState
    },
    isRouteActive (itemPath) {
      if (!itemPath) return false

      const currentPath = this.$route.path
      const normalizedItemPath = itemPath.replace(/\/$/, '')
      const normalizedCurrentPath = currentPath.replace(/\/$/, '')
      return normalizedCurrentPath === normalizedItemPath || normalizedCurrentPath.startsWith(normalizedItemPath + '/')
    }
  }
}
</script>

<style scoped>
:deep(.v-list--density-compact .v-list-item-title) {
  font-size: 0.75rem !important;
}

:deep(.v-toolbar) {
  height: 56px !important;
}

:deep(.v-toolbar__content) {
  height: 56px !important;
}

:deep(.v-toolbar) {
  height: 56px;
}

:deep(.v-navigation-drawer) {
  top: 0px !important;
  height: 100% !important;
  z-index: 10000 !important;
}

/* :deep(.v-navigation-drawer .v-navigation-drawer__prepend) {
  display: none;
} */

.v-navigation-drawer--mini-variant .v-list-item > *:first-child {
  margin-right: 0px !important;
}

.clickable {
  cursor: pointer;
}

.group-header {
  width: 100%;
}

.group-header :deep(.v-list-group__header) {
  padding: 0;
}

.group-item {
  min-height: 12px;
}

.navbar .v-list-item--active {
  border-radius: 10px;
}

.navbar .v-list-item--active:before {
  opacity: 0;
}

.navbar .v-list-item:before {
  border-radius: 10px;
}

.navbar {
  z-index: 10;
}

@media (max-width: 1260px) {
  /*
  Bigger z-index then product fruits containers
  */
  .navbar {
    z-index: unset;
  }
  .v-navigation-drawer--open,
  .v-menu__content {
    z-index: 10002992 !important;
  }
}

#company-logo {
  border-radius: 5px;
  height: 68px;
}

#company-logo :deep([class*='overlay']) {
  background: unset;
}

.user-item {
  transition: background-color 0.4s;
}

.unread-messages-count {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  z-index: 9;
}

.normalized-link-color {
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity)) !important;
}

:deep(.v-navigation-drawer--mini-variant .v-list-item__spacer) {
  display: none;
}

:deep(.v-list-item__spacer) {
  display: none;
}

:deep(.list-item--variant-text) {
  color: unset;
}

.user-details .truncate {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.portrait {
  border-radius: 100%;
  object-fit: cover;
  width: 34px;
  flex: unset;
  margin-right: 8px;
}

.user-section {
  position: relative;
  width: 100%;
  z-index: 100000;
}

.user-section :deep(.v-list-item--variant-text) {
  min-width: 100%;
}

.user-section .truncate {
  max-width: calc(100% - 20px);
  min-width: 0;
}

.user-section .v-list-item-content > div {
  min-width: 0;
}

.user-details {
  min-width: 0;
  flex: 1;
  margin-right: 8px;
}

.truncate {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-button {
  background-color: transparent !important;
  position: absolute;
  right: 8px;
}

.user-section:hover .menu-button {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.portrait {
  border-radius: 100%;
  object-fit: cover;
  width: 34px;
}

.navigation-drawer-height ::v-deep(.v-navigation-drawer__content) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.nav-item {
  padding-left: 10px !important;
}

.navigation-drawer-height ::v-deep(.v-list:last-child),
.navigation-drawer-height ::v-deep(.v-navigation-drawer__append) {
  margin-top: auto;
  margin-bottom: 0;
}

.upgrade-button {
  text-transform: unset;
  font-weight: 700;
  padding: 0 8px !important;
  margin-right: 8px;
}

.usage-counter {
  padding: 0 !important;
}

.news-icon {
  margin: 8px 0 !important;
  margin-bottom: 0;
  top: 4px;
  position: relative;
}

.news-icon .unread-messages-count {
  top: -4px;
}

::v-deep(.v-app-bar__nav-icon .v-icon) {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 16px;
}

.navigation-container {
  display: flex;
  flex-direction: column;
  height: v-bind("isUsageCounterActive ? 'calc(100% - 50px)' : '100%'");
}

.primary-navigation {
  flex: 1;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.bottom-navigation {
  margin-top: 0;
  min-height: fit-content;
}

.navigation-drawer-height ::v-deep(.v-navigation-drawer__content) {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
  padding-inline: 8px;
}
</style>
