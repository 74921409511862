<template>
  <AuthView>
    <div :style="cssProps" class="flex-1 flex-grow-1 d-flex flex-column justify-space-around">
      <div>
        <h2 class="text-center login-title">
          <span>{{ $t('login.title') }}</span>
        </h2>
        <h2 class="text-center login-title pb-8">
          {{ $t('login.subtitle') }}
        </h2>
        <LoginForm />
        <div v-if="showSignup" class="text-center register d-flex justify-center">
          <h4>
            {{ $t('login.register.question') }}
          </h4>
          <a
            :style="{ color: $vuetify.theme.global?.current?.colors?.blue }"
            href="/product-packages"
            class="ml-2 register-link"
            >{{ $t('login.register.link') }}</a
          >
        </div>
      </div>
    </div>
  </AuthView>
</template>

<script>
import LoginForm from '../LoginForm'
import AuthView from '../../AuthView'
import brandingMixin from '@/mixins/branding'
import featureNames from '@/lib/featureNames'
import { signup } from '@/lib/features'
import { capitalizeFirstLetter } from '@/lib/capitalize'

export default {
  components: { LoginForm, AuthView },
  mixins: [brandingMixin],
  data () {
    return {
      featureNames
    }
  },
  computed: {
    cssProps () {
      return {
        '--dark-blue': this.$vuetify.theme.global?.current?.colors?.['markero-dark-blue']
      }
    },
    showSignup () {
      return signup?.isActive
    }
  },
  methods: { capitalizeFirstLetter }
}
</script>
<style scoped>
/* Hover Styles */
.hover:hover {
  cursor: pointer;
}

/* Login Title Styles */
.login-title {
  font-family: 'Poppins', sans-serif;
  color: #252525;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  font-style: normal;
}

/* Register Markero Styles */
.register h4,
.register a {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
}

.register a {
  font-weight: 700;
  text-decoration: none;
}

.register a:hover {
  color: var(--dark-blue) !important;
}

@media (max-width: 350px) {
  .register-link {
    margin-left: 2px !important;
  }
}
</style>
