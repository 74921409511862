import * as Sentry from '@sentry/vue'
import { productFruits } from 'product-fruits'
import IDENTITY from '@/queries/Identity.gql'
import IubendaCookiePurposes from '@/lib/enums/IubendaCookiePurposes'
import { getConsentCookie } from '@/lib/cookieHandling'
import { reactive } from 'vue'

const events = {
  VirtualPageView: 'VirtualPageView',
  Event: 'Event'
}

/**
 * Handles tracking of page views and events.
 * It considers Matomo, Google Tag Manager and Product Fruits.
 * The idea is to have a single point of tracking in the application.
 *
 * It's important to only send data to the tracking tools if the user has given consent. Otherwise only basic data must be sent.
 * Can the identity object passed through an initialize method? Or should the query be executed here separately?
 * Events should be sent only when those data are available.
 *
 * @see https://docs.google.com/spreadsheets/d/1u0Ld55qu9pSUsnUT5Mv4xnV_FJSKGhZraRree0iMoag for defined tracking events
 *
 * If you want to test Friendly / Matomo tracking, you can activate the preview mode in the Matomo settings
 * and append ?mtmPreviewMode={{containerId}}&mtmSetDebugFlag=1 to the URL (where containerId is the Matomo container ID).
 */
function createTrackingService (apolloProvider, getAuth) {
  const state = reactive({
    apolloProvider
  })

  /**
   * Tracks a page view. It uses the current route to determine the page title and URL.
   *
   * If `useActualPath` is set to true we'll use the path as shown in the url bar.
   * Means: if you have a route like `/products/:id` and in the url bar you see `/products/123`,
   * we'll use `/products/123`. Otherwise we'll use the route path `/products/:id`.
   * To set `useActualPath` add it to the route meta object like this:
   * ```js
   * meta: {
   *   tracking: {
   *     useActualPath: true
   *   }
   * }
   * ```
   *
   * @param {Object} route Vue Router route object (current page)
   */
  async function pageView (route) {
    const { useActualPath = false } = route.meta?.tracking || {}
    const path = useActualPath ? route.path : route.matched[0].path
    const url = new URL(path, window.location.origin).toString()

    await _push({
      event: events.VirtualPageView,
      title: route.name,
      url
    })
  }

  /**
   * Tracks an event in two ways:
   * - As a general event with the object and action as separate fields. It's primarily used for Matomo analysis.
   *   e.g. { event: 'Event', object: 'Button', action: 'Click', label: 'SignUp', value: '123' }
   * - As a specific event with the object and action as the event name
   *   e.g. { event: 'Button Click SignUp' }
   * This way in the tag manager we can use either depending on the use case.
   *
   * @param {string} object Event object name, e.g. 'Button', 'Form', 'Link', 'Topic'
   * @param {string} action Event action name, e.g. 'Click', 'Submit', 'Download', 'Created'
   * @param {string} [label=''] Event label, e.g. 'Sign Up', 'Download PDF' – label should be stable against changes, so better don't use translations
   * @param {*} [value=''] Event value, e.g. '123', 'PDF', true
   */
  async function event (object, action, label = '', value = '') {
    if ((!object || !action)) {
      const message = `Tracking event is missing object or action: ${JSON.stringify({ object, action })}`
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(message)
      } else {
        Sentry.captureMessage(message, {
          tags: {
            category: 'tracking'
          }
        })
      }
    }

    await _push({
      event: events.Event,
      object,
      action,
      label,
      value
    })

    const composedEvent = `${object} ${action} ${label}`.trim()

    await _push({
      event: composedEvent,
      value
    })

    _pushToProductFruits(composedEvent, value)
  }

  /**
   * Generic method to push data to the tracking tools.
   * @param {Object} data
   */
  async function _push (data) {
    const enhancedData = await _enhanceData(data)
    _pushToMatomo(enhancedData)
    _pushToGtm(enhancedData)
  }

  /**
   * Pushes data to Matomo tracking
   * @param {Object} data The tracking data
   */
  function _pushToMatomo (data) {
    const _mtm = window._mtm
    if (_mtm) {
      _mtm.push(data)
    }
  }

  /**
   * Pushes data to Google Tag Manager
   * @param {Object} data The tracking data
   */
  function _pushToGtm (data) {
    const dataLayer = window.dataLayer
    if (dataLayer) {
      dataLayer.push(data)
    }
  }

  /**
   * Pushes event to Product Fruits
   * @param {string} composedEvent The event name
   * @param {*} value The event value
   */
  function _pushToProductFruits (composedEvent, value) {
    productFruits.safeExec(() => {
      window.productFruits.api.events.track(composedEvent, {
        value
      })
    })
  }

  /**
   * Enhances the provided data with user and company information if available.
   * It's only available if the user has given tracking consent and is authenticated.
   *
   * @param {Object} data - The data to be enhanced.
   * @returns {Promise<Object>} A promise that resolves to the enhanced data.
   */
  async function _enhanceData (data) {
    const identityData = await _getIdentityRelatedData()
    const auth = getAuth()

    return {
      ...data,
      ...identityData,
      isAuthenticatedUser: auth?.isAuthenticated,
      // It is highly important that the consent is added, as we use it
      // in the tag manager to trigger the respective tags.
      consent: _getConsent()
    }
  }

  /**
   * Asynchronously retrieves identity-related data.
   *
   * This function loads the identity and extracts user and company information
   * if the identity is available (which means also consent is granted).
   * The returned object contains user and company details.
   *
   * @returns {Promise<Object>} A promise that resolves to an object containing
   *                            user and company information, or an empty object
   *                            if the identity is not available.
   */
  async function _getIdentityRelatedData () {
    const identity = await _loadIdentity()

    if (identity) {
      const { user, company } = identity
      return {
        user: {
          userId: user.id,
          appLocale: user.appLocale,
          email: user.email,
          role: user.role?.name ?? ''
        },
        company: {
          companyId: company.id,
          productType: company.productType,
          country: company.country
        }
      }
    }

    return {}
  }

  /**
   * Loads the identity of the authenticated user if tracking consent is given.
   *
   * This method checks if tracking consent is provided and if the user is authenticated.
   * If both conditions are met, it attempts to fetch the user's identity using an Apollo GraphQL query.
   * If the query fails, the error is captured and logged using Sentry.
   *
   * @returns {Promise<Object|null>} The identity data if successful, or null if tracking consent is not given or an error occurs.
   */
  async function _loadIdentity () {
    const auth = getAuth()
    if (!isMeasurementConsentGiven() || !auth?.isAuthenticated) {
      return null
    }

    try {
      const { data } = await state.apolloProvider.defaultClient.query({
        query: IDENTITY,
        fetchPolicy: 'cache-first'
      })
      return data.identity
    } catch (err) {
      Sentry.captureException(err, {
        tags: {
          category: 'tracking',
          user: auth?.user?.id
        },
        extra: {
          message: 'Failed to load identity'
        }
      })
      return null
    }
  }

  /**
   * Checks if tracking consent is given by the user.
   *
   * @returns {boolean} true if tracking consent is given, false otherwise.
   */
  function isMeasurementConsentGiven () {
    const consent = _getConsent()
    return consent.measurement
  }

  /**
   * Retrieves the user's consent preferences for various tracking purposes.
   *
   * This method checks for consent using two different mechanisms:
   * 1. Iubenda consent banner
   * 2. Proprietary consent banner
   *
   * Known issues:
   * - The Iubenda consent solution is not initialized on the page load, so the preferences may not be available in the PageView event.
   *
   * @returns {Object} An object representing the user's consent preferences with the following properties:
   * - functionality {boolean}: Consent for functionality tracking.
   * - experience {boolean}: Consent for experience tracking.
   * - measurement {boolean}: Consent for measurement tracking.
   * - marketing {boolean}: Consent for marketing tracking.
   */
  function _getConsent () {
    const consent = {
      functionality: false,
      experience: false,
      measurement: false,
      marketing: false
    }

    // Iubenda consent banner
    if (window._iub?.cs) {
      const preferences = window._iub.cs.api.getPreferences()
      const purposes = preferences?.purposes || {}

      Object.entries(consent).forEach(([key]) => {
        const purposeKey = IubendaCookiePurposes[key.toUpperCase()]
        consent[key] = purposes[purposeKey] || false
      })
    // Proprietary consent banner
    } else if (getConsentCookie()?.includes('tracking')) {
      consent.measurement = true
    }

    return consent
  }

  return {
    pageView,
    event,
    isMeasurementConsentGiven
  }
}

/**
 * Vue plugin for tracking functionality
 */
export default {
  /**
   * Install the tracking plugin
   * @param {import('vue').App} app - Vue 3 app instance
   * @param {Object} options - Plugin options containing apolloProvider
   */
  install (app, options) {
    const getAuth = () => app.config.globalProperties.$auth
    const tracking = createTrackingService(options.apolloProvider, getAuth)

    app.config.globalProperties.$tracking = tracking
    app.provide('tracking', tracking)
  }
}
