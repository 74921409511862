<template>
  <v-card
    border
    class="statistics-card"
  >
    <div class="pa-5">
      <div
        class="w-full d-flex justify-space-between"
      >
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <div
              class="text-h6 mt-2 text-grey font-weight-light text-truncate d-inline-block"
              v-bind="props"
            >
              {{ title }}
            </div>
          </template>
          <span>
            {{ title }}
          </span>
        </v-tooltip>

        <v-icon
          class="static-gradient-background background-clip-icon rounded-circle pa-2 bg-primary"
          :color="iconColor"
          size="large"
        >
          {{ icon }}
        </v-icon>
      </div>
      <div class="pb-0 mt-2 font-weight-medium statistics-number">
        <v-progress-circular
          v-if="!statistic"
          indeterminate
        />
        <div
          v-else
          class="d-inline-block text-truncate"
          style="max-width:100%"
        >
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <span
                v-bind="props"
              >{{ statistic }}</span>
            </template>
            <span>{{ statistic }}</span>
          </v-tooltip>
        </div>
      </div>

      <div class="mt-2 d-flex text-no-wrap">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <div
              class="text-subtitle-1 text-grey text-truncate"
              v-bind="props"
            >
              {{ subTitle }}
            </div>
          </template>
          <span>
            {{ subTitle }}
          </span>
        </v-tooltip>
        <div
          class="text-subtitle-1 text-grey"
        >
          :
          <v-progress-circular
            v-if="!subStatistic"
            indeterminate
            :size="15"
            :width="2"
          />
          <span v-else>{{ subStatistic }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    statistic: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subStatistic: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.statistics-card:hover{
  cursor: default;
}
.statistics-number{
  font-size: clamp(20px, 20px + 1vw ,2.125rem);
}
</style>
