import emitter from 'tiny-emitter/instance'

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}

export const eventNames = {
  SHOW_ANIMATION: 'showAnimation',
  TRIGGER_ANIMATION: 'triggerAnimation',
  SHOW_SNACKBAR: 'showSnackbar',
  USER_LOGGED_IN: 'userLoggedIn',
  SUBMIT_CREATE_LEAD_FORM: 'submitCreateLeadForm',
  RESET_CREATE_LEAD_FORM: 'resetCreateLeadForm',
  SUBMIT_FEEDBACK_FORM: 'submitFeedbackForm',
  RESET_FEEDBACK_FORM: 'resetFeedbackForm',
  OPEN_FEEDBACK_FORM: 'openFeedbackForm',
  CHANGE_FEATURE_CONFIG: 'changeFeatureConfig',
  CRM_UPDATED: 'crmUpdated',
  PLAN_CREATED: 'planCreated' // Markero only event triggered after the plan(BEGINNER OR ADVANCED) is created/updated.
}
