<template>
  <a
    class="text-primary"
    href="#"
    @click.prevent="openPreferences"
    v-text="$t('legal.links.privacyPreferences')"
  />
</template>

<script>
export default {
  methods: {
    openPreferences () {
      if (window._iub) {
        window._iub.cs.api.openPreferences()
      }
    }
  }
}
</script>
