/**
 * Sanitizes a feature value by removing all characters except alphanumeric, hyphens and underscores
 *
 * @param {*} value - The value to sanitize
 * @returns {string} Sanitized string containing only alphanumeric characters, hyphens and underscores
 *
 * @example
 * sanitizeFeatureValue('hello@world!') // returns 'helloworld'
 * sanitizeFeatureValue(123) // returns '123'
 */
export const sanitizeFeatureValue = (value) => {
  if (typeof value !== 'string') {
    return String(value)
  }
  return value.replace(/[^a-zA-Z0-9-_]/g, '')
}
