/**
* Vue I18n is an internationalization plugin for Vue.js. It easily integrates localization features.
*
* This file creates a vue-i18n instance with the following configuration:
*    const i18n = createI18n({
*      locale: 'en', // set locale
*      messages, // set locale messages
*    })
*
* Usage:
*
* In Vue components, you can now use the t method to translate your application.
* Template example:
*    <p>{{ t('foo.bar') }}</p>
*
*    <MyComponent :label="t('foo.bar')" />
*
* Script example using Composition API:
*    import { useI18n } from 'vue-i18n'
*    const { t } = useI18n()
*    const label = t('foo.bar')
*
* You can change the locale using the locale.value property:
*    const { locale } = useI18n()
*    locale.value = 'fr'
*
* The translation messages are located in the src/i18n directory. Each language has its own file.
*
* For more information, check out the official Vue I18n documentation: https://vue-i18n.intlify.dev/
*/
import { createI18n } from 'vue-i18n'
import features from '@/lib/features'
import deepMergeObjects from '@/lib/deepMerge'

// Import translations using Vite's glob import
const translations = import.meta.glob('@/i18n/*.json', {
  eager: true,
  import: 'default'
})

// Process translations into messages object
const messages = {
  tr: {},
  en: {},
  de: {}
}

// Parse translations from glob imports
Object.entries(translations).forEach(([path, translation]) => {
  const lang = path.match(/\/([^/]+)\.json$/)[1]
  if (messages[lang]) {
    messages[lang] = translation
  }
})

/**
 * List of available locales filtered by feature configuration
 */
const availableLocales = Object.keys(messages).filter((locale) =>
  features?.i18n?.config?.availableLocales?.includes(locale)
)

const STORAGE_KEY = 'appLocale'

/**
 * Retrieves the locale from localStorage
 * @returns {string|null} The stored locale or null if not found
 */
function getLocaleFromStorage () {
  return localStorage.getItem(STORAGE_KEY)
}

/**
 * Saves the locale to localStorage
 * @param {string} locale The locale to store
 */
function saveLocaleToStorage (locale) {
  localStorage.setItem(STORAGE_KEY, locale)
}

/**
 * Gets the locale from browser settings if it matches available locales
 * @returns {string|null} The matched locale or null if none found
 */
function getBrowserLocale () {
  for (const browserLocale of window.navigator.languages) {
    if (availableLocales.includes(browserLocale)) {
      return browserLocale
    } else {
      const language = browserLocale.split('-')[0]
      if (availableLocales.includes(language)) {
        return language
      }
    }
  }
  return null
}

/**
 * Gets the default locale from features config or falls back to 'en'
 * @returns {string} The default locale
 */
function getDefaultLocale () {
  return features?.i18n?.config?.defaultLocale || 'en'
}

/**
 * Determines the client locale based on storage, browser settings, or default
 * @returns {string} The determined locale
 */
function getClientLocale () {
  const selectedLocale = getLocaleFromStorage()
  if (selectedLocale) {
    return selectedLocale
  }

  const browserLocale = getBrowserLocale()
  if (browserLocale) {
    return browserLocale
  }

  return getDefaultLocale()
}

// Initialize i18n instance immediately
const i18nInstance = createI18n({
  locale: getClientLocale(),
  fallbackLocale: getDefaultLocale(),
  messages,
  legacy: false // Enable Composition API mode
})

/**
 * Loads and applies translation overrides asynchronously
 */
async function loadOverrides () {
  /**
   * Load platform specific translations if they are configured in the i18n feature
   * @param {string} language The language code to load overrides for
   * @returns {object|null} The loaded translation override or null if not found
   */
  const translations = import.meta.glob('./*.json', { eager: true })

  async function platformsSpecificTranslation (language) {
    if (features?.i18n?.config?.languageOverrides[language]) {
      const fileName = features.i18n.config.languageOverrides[language]
      const path = `./${fileName}.json`
      return translations[path]?.default || null
    }
    return null
  }

  for (const [lang, baseTranslations] of Object.entries(messages)) {
    const override = await platformsSpecificTranslation(lang)
    if (override) {
      const mergedMessages = deepMergeObjects(baseTranslations, override)
      i18nInstance.global.setLocaleMessage(lang, mergedMessages)
    }
  }
}

// Start loading overrides but don't block initialization
loadOverrides()

const i18n = {
  availableLocales,

  /**
   * Returns the i18n instance.
   * @returns {Object} i18n instance
   */
  getInstance () {
    return i18nInstance
  },

  /**
   * Changes the locale of the i18n instance and saves it to the local storage.
   * @param {string} locale The new locale to set
   */
  changeLocale (locale) {
    i18nInstance.global.locale.value = locale
    saveLocaleToStorage(locale)
  }
}

export default i18n
