import { getInstance } from '@/plugins/feature-config'
import { getInstance as getAuthInstance } from '../auth/index'
import { authGuard } from '../guards/auth'
import LICENSE_AGREEMENT from '@/queries/LicenseAgreement.gql'
import featureNames from '@/lib/featureNames'

/**
 * Checks if current user is responsible for the license agreement signing.
 * If so and a (updated) license agreement exists and it forces the user to accept it before using the app.
 * Routes to the license agreement page, if new license agreement exists or first time login of responsible user.
 *
 * @param {string} apolloProvider apollo instance
 * @returns {void}
 * @throws {Error}
 */
export function getLicenseAgreementGuard (apolloProvider) {
  return (to, from, next) => {
    authGuard(async () => {
      try {
        const auth = getAuthInstance()

        if (!auth.isAuthenticated) {
          next()
          return
        }

        // Legal pages that have to be visible even if no license agreement has been accepted
        const publicPages = ['privacy', 'avv', 'legal', 'company-creation', 'logout', 'registration']
        if (publicPages.includes(to.name)) {
          next()
          return
        }

        const featureConfig = getInstance()
        await featureConfig.loadAllFeaturesForCompany(auth.user.companyId, { apolloProvider, reload: false })
        const licenseAgreementFeature = featureConfig.featuresBySlug[featureNames.LICENSE_AGREEMENT]

        if (licenseAgreementFeature?.isActive) {
          if (to.name === 'licenseAgreement') {
            next()
            return
          }

          const apolloClient = apolloProvider.defaultClient
          const { data: { company } } = await apolloClient.query({
            query: LICENSE_AGREEMENT,
            variables: {
              id: auth.user.companyId
            }
          })

          if (!company) {
            throw new Error('Company not found')
          }

          const lastAcceptedAt = new Date(company.licenseAgreement?.acceptedAt).getTime()
          const lastModifiedAt = new Date(licenseAgreementFeature.config.lastModifiedAt).getTime()
          const needsReacceptance = lastAcceptedAt < lastModifiedAt

          if (!company.licenseAgreement?.acceptedAt || needsReacceptance) {
            next({ name: 'licenseAgreement', replace: true })
            return
          }
        } else if (to.name === 'licenseAgreement') {
          next({ name: 'dashboard', replace: true })
          return
        }

        next()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('License agreement guard error:', error)
        next(false)
      }
    })
  }
}
